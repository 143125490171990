.horizontal-form {
	// position: relative;
	// z-index: 1;
	padding: 60px 0 35px;
	background-color: #000;
	box-shadow: 0px -20px 50px -15px rgba(238, 211, 124, 0.35);
	@include respond-to('large') {
		padding: 25px 0;
	}
}

.section-limit {
	&_form {
		position: relative;
		z-index: 2;
		max-width: 1350px;
		width: 100%;
		margin: 0 auto;
	}
}

.form-horizontal {
	// padding: 20px 100px 15px;
	// background-color: rgba($color: #cbf391, $alpha: 0.6);
	// box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
	@include respond-to('huge') {
		// padding: 20px;
	}
	@include respond-to('small') {
		// padding: 15px;
	}
	& .contact-us-form {
		overflow: hidden;
	}
	& .contact-us-form__title {
		margin-bottom: 20px;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: #fff;
		line-height: 1;
		@include respond-to('small') {
			font-size: 18px;
		}
		& span {
			white-space: nowrap;
			font-size: 20px;
			font-weight: 900;
			@include respond-to('small') {
				font-size: 18px;
			}
		}
	}
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 240px;
		width: 100%;
		@include respond-to('larger') {
			// max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_ta {
			max-width: 310px;
			@include respond-to('huge') {
				max-width: 240px;
			}
			@include respond-to('large') {
				margin-bottom: 10px;
			}
		}
		& textarea {
			height: 45px;
			padding: 12px 10px 0;
			max-width: 310px;
			box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.1);
			@include respond-to('medium') {
				max-width: 100%;
			}
			@include respond-to('extra-small') {
				// height: 50px;
				// padding: 12px 10px 0;
			}
		}
		&_button {
			max-width: 180px;
			float: $left;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				// max-width: 160px;
			}
			@include respond-to('larger') {
				display: block;
				margin: 0 auto;
				max-width: 240px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				// min-width: 260px;
			}
		}
		&_check {
			display: block;
			margin-top: 8px;
			max-width: none;
			@include respond-to('small') {
				& .ftr-check-uncheck {
					margin-bottom: 5px;
				}
			}
		}
	}
	& .newslatter-input {
		height: 45px;
		width: 100%;
		padding: 11px 10px 10px;
		// height: 100%;
		background-color: #fff;
		color: #838383;
		font-weight: 300;
		box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.3);
		font-size: 16px;
		border: none;
		border-radius: 0;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 16px;
			color: #838383;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 45px;
		font-size: 18px;
		font-weight: 900;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
		background-image: $grad;
		border: none;
		color: #000;
		&:active {
			@include btn-effect-active;
		}
	}

	& .help-block {
		font-size: 16px;
		color: #fff !important;
	}

	& .form-control:focus {
		border-radius: 0;
	}

	& .ftr-check-uncheck {
		position: relative;
		display: inline-block;
		width: 19px;
		height: 19px;
		margin-#{$left}: 5px;
		// margin-top: 5px;
		vertical-align: top;
		top: 3px;
		label {
			width: 19px;
			height: 19px;
			cursor: pointer;
			position: absolute;
			background-color: #fff;
			border: 1px solid #bebebe;
			box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.1);
			#{$left}: 0;
			top: 0;
			&:after {
				content: '';
				width: 12px;
				height: 6px;
				position: absolute;
				top: 4px;
				#{$left}: 3px;
				border: 2px solid #000;
				border-top: none;
				border-right: none;
				background: 0 0;
				opacity: 0;
				transform: rotate(-45deg);
			}
		}
		input[type=checkbox] {
			visibility: hidden;
			&:checked + label::after {
				opacity: 1;
			}
		}
		&_side {
			margin-top: 20px;
			label::after {
				top: -2px;
			}
		}
	}

	& .ftr-checkbox-para {
		display: inline-block;
		// max-width: 75px;
		@include respond-to('larger') {
			// max-width: 150px;
		}
		& > p {
			font-size: 18px;
			font-weight: 400;
			color: #fff;
			margin: 0;
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
		&_side {
			margin-top: 15px;
			max-width: 128px;
		}
	}

	& p.msg-success {
		color: #fff;
	}
}
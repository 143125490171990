.wrap-team-box {
	display: block;
	&:hover {
		cursor: pointer;
		.team-box-image__item {
			transform: scale(1.1);
		}
	}
	& hr {
		margin: 38px 0;
		border-top: 1px solid #c6c6c6;
		@include respond-to('medium') {
			margin: 20px 0;
		}
	}
	&:last-child {
		& hr {
			display: none;
		}
	}
}

.team-box-image {
	max-width: 300px;
	width: 100%;
	height: 210px;
	overflow: hidden;
	padding: 9px;
	border: 2px solid $accent;
	@include respond-to('small') {
		max-width: 300px;
		margin: 0 auto;
	}
}

.team-box-image__item {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	transition: all 0.6s;
}

.team-box-data {
	position: relative;
	// padding-#{$right}: 20px;
	@include respond-to('medium') {
		// padding-#{$right}: 0;
	}
	@include respond-to('small') {
		margin-top: 15px;
	}
	&__title {
		font-size: 25px;
		font-weight: 700;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			font-size: 22px;
		}
	}
	&__subtitle {
		font-size: 20px;
		font-weight: 400;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 15px;
		}
	}
	&__content {
		overflow: hidden;
		margin-top: 10px;
    text-align: justify;
    font-size: 18px;
    font-weight: 300;
    color: #000;
    margin: 10px 0 0;
    line-height: 1.2;
    @include respond-to('extra-small') {
      font-size: 16px;
    }
		& > p {
			font-size: 18px;
			font-weight: 300;
			color: #000;
			margin: 0;
			line-height: 1.2;
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
	}
	&__link.main-link {
		margin-top: 33px;
		border-top: 2px solid $accent;
    font-weight: 600;
    display: inline-block;
		@include respond-to('extra-small') {
			margin-top: 20px;
		}
	}
}

.homepage-box {
	position: relative;
	display: block;
	max-width: 403px;
	margin: 0 auto;
	width: 100%;
	z-index: 1;
	&:hover {
		& .homepage-box__shadow {
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.85);
			// background: linear-gradient(to bottom, rgba(173, 137, 59, 0.85) 0%, rgba(240, 209, 95, 0.85) 40%, rgba(240, 209, 95, 0.85) 60%, rgba(173, 137, 59, 0.85) 100%);
		}
		& .homepage-box__title {
			opacity: 0;
			&::before {
				background-color: transparent;
			}
		}
	}
	&__image {
		position: relative;
		z-index: auto;
		// overflow: hidden;
		height: 419px;
		background-size: cover;
		background-position: center;
		@include respond-to('extra-small') {
			height: 380px;
		}
		@include respond-to('mobile-m') {
			height: 300px;
		}
	}
	&__image-border {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		border: 8px solid $accent;
		border-image-source: repeating-linear-gradient(to bottom, #a17c32, #f2d780, #a17c32);
		border-image-slice: 20;
	}
	&__title {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		h2 {
			position: relative;
			font-size: 30px;
			font-weight: 900;
			padding: 21px 5px;
			margin: 0;
			z-index: 10;
			color: #fff;
			@include respond-to('larger') {
				font-size: 25px;
			}
			@include respond-to('mobile-m') {
				font-size: 22px;
			}
		}
		&::before {
			content: " ";
			background-color: rgba(0, 0, 0, 0.85);
			position: absolute;
			height: 100%;
			left: 0;
			right: 0;
			z-index: 0;
		}
	}
	&__shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(173, 137, 59, 0) 0%, rgba(240, 209, 95, 0) 40%, rgba(240, 209, 95, 0) 60%, rgba(173, 137, 59, 0) 100%);
		opacity: 0;
		transition: opacity 0.2s ease;
	}
}

.shadow-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	&__title {
		font-size: 30px;
		font-weight: 900;
		padding: 21px 5px;
		margin: 0;
		z-index: 10;
		color: #fff;
		@include respond-to('larger') {
			font-size: 25px;
		}
		@include respond-to('mobile-m') {
			font-size: 22px;
			padding: 15px 5px;
		}
	}
	&__desc {
		width: 70%;
		margin: 0 auto;
		padding-top: 15px;
		@include respond-to('extra-small') {
			width: 90%;
			padding-top: 0;
		}
		& p {
			margin: 0;
			font-size: 18px;
			font-weight: 400;
			color: #fff;
			line-height: 1.2;
			@include respond-to('mobile-m') {
				font-size: 16px;
			}
		}
	}
	&__link {
		display: inline-block;
		margin-top: 25px;
		font-size: 25px;
		font-weight: 400;
		@include link_no-hover(#000);
		border-bottom: 2px solid #000;
	}
}
$main-social-color: $accent;
$social-share-color-black: #000;
$social-share-color-white: #fff;

.social-share-btns {
	border-top: 1px solid #383838;
	padding: 35px 0 25px;
	&_width {
		width: 20%;
		@include respond-to('medium') {
			width: 16.66%;
		}
		@include respond-to('small') {
			width: 33.33%;
		}
		@include respond-to('extra-small') {
			width: 50%;
		}
	}
	&_float {
		float: $right;
	}
	&__google,
	&__twitter {
		padding-top: 5px;
	}
	&__whatsapp {
		display: none;
		@include respond-to('medium') {
			display: block;
		}
		@include respond-to('small') {
			clear: both;
		}
		@include respond-to('extra-small') {
			clear: none;
		}
		& > a {
			display: inline-block;
			font-size: 14px;
			font-weight: 300;
			color: $social-share-color-white;
			&:hover,
			&:focus {
				color: $social-share-color-white;
			}
			&:hover {
				text-decoration: none;
			}
			& > img {
				margin-#{$left}: 7px;
			}
		}
	}
	&__send-friend {
		@include respond-to('extra-small') {
			clear: both;
		}
		& > a {
			font-size: 14px;
			font-weight: 300;
			color: $social-share-color-white;
			vertical-align: bottom;
			&:hover,
			&:focus {
				color: $social-share-color-white;
			}
			&:hover {
				text-decoration: none;
			}
			& > .fa-envelope-open {
				color: $main-social-color;
				margin-#{$left}: 10px;
			}
		}
	}
	&__email {
		& > a {
			display: inline-block;
			padding: 2px 14px;
			font-size: 14px;
			font-weight: 300;
			color: $social-share-color-white;
			border: 1px solid $main-social-color;
			white-space: nowrap;
			transition: all 0.3s;
			&:hover,
			&:focus {
				color: $social-share-color-white;
				border: 1px solid $social-share-color-white;
				background-color: $main-social-color;
			}
			&:hover {
				text-decoration: none;
			}
			&:active {
				box-shadow: inset 2px 5px 5px rgba(0, 0, 0, .15);
			}
		}
	}
}
.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	// background: #fff;
	// box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}

.header-desktop {
	height: 350px;
	background-image: url(../images/header-bg.png);
	background-position: bottom center;
	background-size: cover;
	transition: all 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	position: absolute;
	top: 20px;
	#{$left}: 50%;
	transform: translateX(-50%);
	transition: top 0.2s ease;
	& img {
		max-width: 279px;
		transition: max-width 0.2s ease;
		@include respond-to('larger') {
			max-width: 220px;
		}
		@include respond-to('large') {
			max-width: 180px;
		}
	}
}

.main-navi,
.sub-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(52px 0 0 0);
		padding: 0;
		transition: margin 0.2s ease;
		& > li {
			float: $right;
			margin-#{$left}: 20px;
			position: relative;
			@include respond-to('large') {
				margin-#{$left}: 10px;
			}
			& > a {
				display: block;
				border-bottom: 2px solid transparent;
				padding: 4px 1px;
				color: #000;
				font-size: 18px;
				font-weight: 500;
				&:hover {
					border-bottom: 2px solid $accent;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 2px solid $accent;
				}
			}
		}
	}
}

.sub-navi {
	float: $left;
	& li:last-child {
		margin-#{$left}: 0;
	}
}

.header-contact {
	display: inline-block;
	margin-top: 54px;
	margin-#{$right}: 30px;
	vertical-align: top;
	transition: margin 0.2s ease;
	@include respond-to('large') {
		margin-#{$right}: 10px;
	}
	&__facebook {
		display: inline-block;
		& a {
			color: $accent;
		}
		& i {
			color: $accent;
			font-size: 21px;
		}
	}
	&__phone {
		display: inline-block;
		margin-#{$right}: 15px;
		& span {
			font-size: 19px;
			color: #000;
			font-weight: 500;
		}
		& a {
			font-size: 20px;
			font-weight: 900;
			@include link_no-hover($accent);
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: #fff;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		& > img {
			width: 70px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 21px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	box-shadow: 0 3px 20px rgba(0, 0, 0, 0.35);
	background-color: #fff;
	.header-desktop {
		height: 125px;
	}
	.main-navi,
	.sub-navi {
		& > ul {
			margin-top: 45px;
		}
	}
	.header-contact {
		margin-top: 48px;
	}
	.wrap-lang {
		margin-top: 25px;
	}
	.logo {
		top: 2px;
		& img {
			max-width: 143px
		}
	}
}
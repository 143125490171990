.wrapper-all-content {
	background-color: #000;
	background-image: url(../images/main-bg.png);
	background-position: top center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
}

.homepage__buttons {
	padding: 90px 0 20px;
	font-size: 0;
	text-align: center;
	@include respond-to('larger') {
		padding: 70px 0 20px;
	}
	@include respond-to('small') {
		padding: 50px 0 0;
	}
}

.homepage__buttons-wrap {
	display: inline-block;
	width: 33.33%;
	padding: 26px;
	margin-bottom: 72px;
	@include respond-to('larger') {
		padding: 15px;
		margin-bottom: 50px;
	}
	@include respond-to('1100') {
		width: 50%;
	}
	@include respond-to('small') {
		width: 100%;
		margin-bottom: 30px;
	}
	&:nth-child(3n + 1) {
		& .homepage-box__image-border {
			right: -26px;
			top: -32px;
			@include respond-to('larger') {
				right: -20px;
				top: -20px;
			}
		}
	}
	&:nth-child(3n + 2) {
		& .homepage-box {
			position: relative;
			top: -32px;
			@include respond-to('larger') {
				top: -20px;
			}
		}
		& .homepage-box__image-border {
			right: -26px;
			top: 32px;
			@include respond-to('larger') {
				right: -20px;
				top: 20px;
			}
		}
	}
	&:nth-child(3n + 3) {
		& .homepage-box__image-border {
			right: 26px;
			top: -32px;
			@include respond-to('larger') {
				right: 20px;
				top: -20px;
			}
		}
	}
}

.sec-padd {
	padding: 80px 0;
	@include respond-to('huge') {
		padding: 60px 0;
	}
	@include respond-to('large') {
		padding: 45px 0;
	}
	@include respond-to('small') {
		padding: 30px 0;
	}
}

.title {
	margin: 0;
	text-align: center;
	color: #fff;
	font-size: 40px;
	font-weight: 400;
	line-height: 0.9;
	@include respond-to('medium') {
		font-size: 35px;
	}
	@include respond-to('small') {
		font-size: 30px;
	}
	@include respond-to('mobile-m') {
		font-size: 25px;
	}
	& span {
		display: block;
		font-weight: 900;
	}
	&_margin {
		margin: 55px 0 95px;
		@include respond-to('huge') {
			margin: 40px 0;
		}
		@include respond-to('medium') {
			margin: 35px 0;
		}
		@include respond-to('small') {
			margin: 15px 0 25px;			
		}
	}
}

.hp-about {
	box-shadow: 0px -20px 50px -15px rgba(238,211,124,.35);
	position: relative;
	&__title {
		margin-bottom: 50px;
		@include respond-to('large') {
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			margin-bottom: 25px;
		}
		& span {}
	}
	&__desc {
		max-width: 1215px;
		margin: 0 auto;
		font-size: 18px;
		font-weight: 400;
		color: #fff;
		text-align: center;
		@include respond-to('small') {
			font-size: 17px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
		& p {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
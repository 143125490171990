.video-play {
	position: relative;
	min-height: 320px;
	@include respond-to('mobile-l') {
		min-height: 270px;
	}
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.wrapper-banners {
	width: 100%;
	position: relative;
	padding-bottom: 49.92%;
	overflow: hidden;
	height: 0px !important;
	min-height: 320px;
	@include respond-to('small') {
		padding-bottom: 56.25%;
		margin-top: 70px;
	}
	@include respond-to('mobile-l') {
		min-height: 270px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.class-video {
	@include respond-to('medium') {
		min-height: unset;
	}
}

.banner-text {
	position: absolute;
	bottom: 30%;
	#{$right}: 16%;
	max-height: 400px;
	overflow: hidden;
	@include respond-to('huge') {
		top: 35%;
	}
	@include respond-to('larger') {
		top: 30%;
	}
	@include respond-to('medium') {
		top: 20%;
		padding-#{$left}: 10px;
	}
	@include respond-to('small') {
		position: static;
		top: auto;
		#{$right}: auto;
		transform: none;
		padding-bottom: 30px;
		padding-top: 15px;
		padding-#{$left}: 0;
		text-align: center;
		@include respond-to('extra-small') {
			padding-top: 0;
		}
	}
	&__title {
		max-width: 350px;
		width: 100%;
		font-size: $font-size-title;
		font-weight: 400;
		color: $accent;
		line-height: 1;
		@include respond-to('small') {
			margin: 5px auto 0 !important;
			text-align: center;
			font-size: 22px;
		}

	}
	&__description {
		max-height: 115px;
		overflow: hidden;
		max-width: 300px;
		width: 100%;
		margin-top: 20px;
		font-size: $font-size-base;
		font-weight: 300;
		color: $accent;
		line-height: 1.2;
		@include respond-to('small') {
			margin: 5px auto !important;
			text-align: center;
			font-size: 16px;
		}
	}
	&__link {
		display: inline-block;
		margin-top: 50px;
		padding-top: 10px;
		border-top: 2px solid $accent;
		font-size: $font-size-base;
		font-weight: 300;
		color: $accent;
		@include link_no-hover($accent);
		@include respond-to('larger') {
			margin-top: 25px;
		}
		@include respond-to('small') {
			margin: 5px auto 0 !important;
			padding-top: 0 !important;
			border-top-width: 1px !important;
			font-size: 16px;
		}
	}
}

.player__volume-btn {
	position: absolute;
	bottom: 150px;
	#{$left}: 30px;
	color: $accent;
	display: inline-block;
	width: 50px;
	height: 50px;
	z-index: 999;
	@include respond-to('medium') {
		#{$left}: 15px;
		bottom: 100px;
	}
	@include respond-to('small') {
		bottom: 40px;
	}
	@include respond-to('extra-small') {
		bottom: 30px;
	}
	@include respond-to('mobile-l') {
		bottom: 5px;
	}
	& i {
		font-size: 42px;
	}
}

footer {
	width: 100%;
	position: relative;
	background-color: #000;
	z-index: 1;
	padding: 20px 0;
	box-shadow: 0 0 70px rgba(238, 211, 124, 0.2);
	@include respond-to('large') {
		padding: 20px 0;
	}
}


.credit-info-txt {
  display: block;
  margin-bottom: 30px;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-right: 0;
	}
	& > p {
		font-size: 16px;
		color: #fff;
		font-weight: 300;
    margin: 0;
    & a{
      font-size: 16px;
      color: #fff;
      font-weight: 300;
    }
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.ftr-links-koteret {
	font-size: 18px;
	font-weight: 300;
	color: #fff;
	margin-bottom: 25px;
	padding-bottom: 20px;
	border-bottom: 1px solid #fff;
	position: relative;
	@include respond-to('extra-small') {
		font-size: 14px;
		margin-bottom: 15px;
		padding-bottom: 10px;
	}
	&:after {
		content: " ";
		position: absolute;
		bottom: -1px;
		left: 0;
		right: 0;
		width: 0%;
		height: 1px;
		background-color: #fff;
		margin: 0 auto;
		transition: all 0.4s;
	}
}

.ftr-wrap-links {
	max-width: 200px;
	@include respond-to('small') {
		margin-bottom: 30px;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {
		div:after {
			width: 100%;
		}
	}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			& > a {
				font-size: 16px;
				font-weight: 300;
				color: #fff;
				&:hover {
					color: #fff;
				}
			}
		}
	}
}

.ftr_seo {
	& p {
		color: #fff;
		font-size: 16px;
		font-weight: 300;
		& a {
			color: #fff;
		}
	}
}

// @include respond-to('extra-small'){
//   .ftr-wrap-nav{
//     & > .col-xs-6{
//       width: 100%;
//     }
//   }
// }
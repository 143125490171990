.wrap-content {
	padding: 20px 0 100px;
	@include respond-to('larger') {
		padding: 20px 0 70px;
	}
	@include respond-to('medium') {
		padding: 20px 0 50px;
	}
	@include respond-to('small') {
		padding: 20px 0 30px;		
	}
	&_gal {
		padding-bottom: 50px;
		@include respond-to('medium') {
			padding-bottom: 30px;
		}
	}
}

.wrap-all-content-not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-text {
	h2,
	h3 {
		font-size: 30px;
		font-weight: 300;
		line-height: 1.2;
		color: #fff;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: #fff;
    line-height: 1.2;
    & a{
      font-size: 20px;
      font-weight: 300;
      color: #fff;
      line-height: 1.2;
    } 
		@include respond-to('medium') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
	&_img {}
}